.MuiTableCell-root .MuiIcon-colorPrimary {
  opacity: 0;
}

.MuiTableCell-root:hover .MuiIcon-colorPrimary {
  opacity: 1;
}

.MuiPaper-root > div > div > div > div:nth-child(2) {
  box-shadow: none !important;
}

.MuiPopover-paper {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

canvas {
  outline: none;
}

body {
  overflow: hidden;
  margin: 0;
}

html {
  font-family: GoogleSans, Roboto, sans-serif !important;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

a {
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button {
  background-color: transparent;
  background-image: none;
}

button:hover,
button:focus,
button:active,
button:visited {
  outline: none;
}

button,
[role="button"] {
  cursor: pointer;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

@font-face {
  font-family: "GoogleSansBold";
  src: url("./fonts/googlesans-bold-webfont.woff2") format("woff2"), url("./fonts/googlesans-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "GoogleSansBoldItalic";
  src: url("./fonts/googlesans-bolditalic-webfont.woff2") format("woff2"), url("./fonts/googlesans-bolditalic-webfont.woff") format("woff");
  font-weight: bolditalic;
  font-style: bolditalic;
}

@font-face {
  font-family: "GoogleSansItalic";
  src: url("./fonts/googlesans-italic-webfont.woff2") format("woff2"), url("./fonts/googlesans-italic-webfont.woff") format("woff");
  font-weight: italic;
  font-style: italic;
}

@font-face {
  font-family: "GoogleSansMedium";
  src: url("./fonts/googlesans-medium-webfont.woff2") format("woff2"), url("./fonts/googlesans-medium-webfont.woff") format("woff");
  font-weight: medium;
  font-style: medium;
}

@font-face {
  font-family: "GoogleSansMediumItalic";
  src: url("./fonts/googlesans-mediumitalic-webfont.woff2") format("woff2"),
    url("./fonts/googlesans-mediumitalic-webfont.woff") format("woff");
  font-weight: mediumitalic;
  font-style: mediumitalic;
}

@font-face {
  font-family: "GoogleSansRegular";
  src: url("./fonts/googlesans-regular-webfont.woff2") format("woff2"), url("./fonts/googlesans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
